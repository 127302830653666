import { default as indexcUMNZSymhdMeta } from "D:/a/1/s/pages/auth/index.vue?macro=true";
import { default as broadcastscIqiAzpID5Meta } from "D:/a/1/s/pages/broadcasts.vue?macro=true";
import { default as incident_45reportA9ha0vmsZtMeta } from "D:/a/1/s/pages/incident-report.vue?macro=true";
import { default as index0lkTn0sf3RMeta } from "D:/a/1/s/pages/index.vue?macro=true";
import { default as supervisor_45visitn9FA6iXyddMeta } from "D:/a/1/s/pages/supervisor-visit.vue?macro=true";
import { default as _91id_93G5rDdJ9wXEMeta } from "D:/a/1/s/pages/v/[id].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexcUMNZSymhdMeta || {},
    component: () => import("D:/a/1/s/pages/auth/index.vue")
  },
  {
    name: "broadcasts",
    path: "/broadcasts",
    meta: broadcastscIqiAzpID5Meta || {},
    component: () => import("D:/a/1/s/pages/broadcasts.vue")
  },
  {
    name: "incident-report",
    path: "/incident-report",
    meta: incident_45reportA9ha0vmsZtMeta || {},
    component: () => import("D:/a/1/s/pages/incident-report.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index0lkTn0sf3RMeta || {},
    alias: ["/menu"],
    component: () => import("D:/a/1/s/pages/index.vue")
  },
  {
    name: "supervisor-visit",
    path: "/supervisor-visit",
    component: () => import("D:/a/1/s/pages/supervisor-visit.vue")
  },
  {
    name: "v-id",
    path: "/v/:id()",
    meta: _91id_93G5rDdJ9wXEMeta || {},
    component: () => import("D:/a/1/s/pages/v/[id].vue")
  }
]