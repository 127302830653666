import type {
    GetBroadcastMessagesForMobileResponse_AudioBroadcastForMobile as AudioBroadcast,
    GetBroadcastMessagesForMobileResponse_VideoBroadcastForMobile as VideoBroadcast,
    GetPinInfoResponse,
} from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';

const broadcastsAcknowledged: Record<string, string | null> = reactive({});
for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith('broadcast-')) {
        broadcastsAcknowledged[key.substring(10)] = localStorage.getItem(key);
    }
}

export default defineNuxtPlugin(() => {
    function acknowledge(...values: string[]) {
        const now = new Date().toISOString();
        values.filter(x => x).forEach((x) => {
            localStorage.setItem(`broadcast-${x}`, now);
            broadcastsAcknowledged[x] = now;
        });
    }
    async function sendServerAcknowledgement(id: string, name: string, isVideo: boolean) {
        const { $ivrWebService } = useNuxtApp();
        const acknowledgeBroadcastFetcher = $ivrWebService.acknowledgeBroadcastPostFetcher();
        try {
            // if it fails it fails, no need to alarm the user
            await acknowledgeBroadcastFetcher.fetch({ id, name, isVideo });
        }
        catch {
            console.error('Failed to acknowledge broadcast on server');
        }
    }
    return {
        provide: {
            broadcastsHelper: {
                broadcastsAcknowledged: broadcastsAcknowledged as Readonly<Record<string, string | null>>,
                async onAudioAcknowledge(broadcast: AudioBroadcast) {
                    if (!broadcast.id) {
                        throw new Error('broadcast.id is required');
                    }
                    if (!this.isAcknowledged(broadcast.id)) {
                        acknowledge(broadcast.id);
                        await sendServerAcknowledgement(broadcast.id, broadcast.name || '[[Untitled Audio Broadcast]]', false);
                    }
                },
                async onVideoAcknowledge(broadcast: VideoBroadcast) {
                    if (!broadcast.id) {
                        throw new Error('broadcast.id is required');
                    }
                    if (!this.isAcknowledged(broadcast.id, broadcast?.requiredIntervalDays)) {
                        acknowledge(broadcast.id);
                        await sendServerAcknowledgement(broadcast.id, broadcast.title || '[[Untitled Video Broadcast]]', true);
                    }
                },
                allAcknowledged(pinInfo: GetPinInfoResponse): boolean {
                    return Boolean(pinInfo.broadcasts.audioBroadcasts?.every(b => this.isAcknowledged(b.id!))
                        && pinInfo.broadcasts.videoBroadcasts?.every(b => !b.required || this.isAcknowledged(b.id!, b.requiredIntervalDays)));
                },
                isAcknowledged(id: string, expirationDays?: number): boolean {
                    if (!(id in broadcastsAcknowledged)) {
                        return false;
                    }
                    if (expirationDays && expirationDays > 0) {
                        if (!broadcastsAcknowledged[id]) {
                            return false;
                        }
                        return dayjs().subtract(expirationDays, 'days').isBefore(broadcastsAcknowledged[id]);
                    }
                    return true;
                },
            },
        },
    };
});
