import revive_payload_client_4sVQNw7RlN from "D:/a/1/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/a/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/a/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "D:/a/1/s/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "D:/a/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "D:/a/1/s/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/a/1/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "D:/a/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "D:/a/1/s/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "D:/a/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/a/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import baseService_5RY0vrFTrE from "D:/a/1/s/node_modules/@psrm/base-service/dist/runtime/baseService.mjs";
import useDirectives_2KnF4DchlO from "D:/a/1/s/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.js";
import createBootstrap_Re1dxEZp3O from "D:/a/1/s/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.js";
import _01_settingsProvider_ktbzeXPzk0 from "D:/a/1/s/plugins/01.settingsProvider.ts";
import _02_mobileApiService_sMuyiOzYyW from "D:/a/1/s/plugins/02.mobileApiService.ts";
import _03_datadogRum_vmPOxchcMP from "D:/a/1/s/plugins/03.datadogRum.ts";
import _30_gpsPunchService_ymnmYSE0Hb from "D:/a/1/s/plugins/30.gpsPunchService.ts";
import _30_identityService_38mQqASceX from "D:/a/1/s/plugins/30.identityService.ts";
import _30_incidentService_F1fIElHWtf from "D:/a/1/s/plugins/30.incidentService.ts";
import _30_ivrBroadcastService_QvmsizlQJW from "D:/a/1/s/plugins/30.ivrBroadcastService.ts";
import _30_ivrWebService_ydZadHrgNx from "D:/a/1/s/plugins/30.ivrWebService.ts";
import _30_provisioningService_3bhuAttxbr from "D:/a/1/s/plugins/30.provisioningService.ts";
import _30_templateService_F0YXH1bWLb from "D:/a/1/s/plugins/30.templateService.ts";
import _30_tenantLookupService_Y0u7KFwiqN from "D:/a/1/s/plugins/30.tenantLookupService.ts";
import _30_trainingVideoService_igHKAiLGQX from "D:/a/1/s/plugins/30.trainingVideoService.ts";
import _30_userAuthenticationService_04IVNPbDxB from "D:/a/1/s/plugins/30.userAuthenticationService.ts";
import _31_s3UploadService_oft8u7vr56 from "D:/a/1/s/plugins/31.s3UploadService.ts";
import auth_vT9JWWT9pN from "D:/a/1/s/plugins/auth.ts";
import broadcastsHelper_l5QX3KEtX1 from "D:/a/1/s/plugins/broadcastsHelper.ts";
import flavorManager_64XkvarXep from "D:/a/1/s/plugins/flavorManager.ts";
import fontawesome_cn2c4tOOHz from "D:/a/1/s/plugins/fontawesome.ts";
import geolocation_ziVmBUOoHS from "D:/a/1/s/plugins/geolocation.ts";
import googleMaps_yobp94ocoP from "D:/a/1/s/plugins/googleMaps.ts";
import googleTagManager_QmmW6lPxbX from "D:/a/1/s/plugins/googleTagManager.ts";
import tenantHostService_Grnhr0X0dS from "D:/a/1/s/plugins/tenantHostService.ts";
import timeProvider_Pwk6BtTnUw from "D:/a/1/s/plugins/timeProvider.ts";
import toastService_F88m7nHGCc from "D:/a/1/s/plugins/toastService.ts";
import vModelState_Uui3jHSKz0 from "D:/a/1/s/plugins/vModelState.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  baseService_5RY0vrFTrE,
  useDirectives_2KnF4DchlO,
  createBootstrap_Re1dxEZp3O,
  _01_settingsProvider_ktbzeXPzk0,
  _02_mobileApiService_sMuyiOzYyW,
  _03_datadogRum_vmPOxchcMP,
  _30_gpsPunchService_ymnmYSE0Hb,
  _30_identityService_38mQqASceX,
  _30_incidentService_F1fIElHWtf,
  _30_ivrBroadcastService_QvmsizlQJW,
  _30_ivrWebService_ydZadHrgNx,
  _30_provisioningService_3bhuAttxbr,
  _30_templateService_F0YXH1bWLb,
  _30_tenantLookupService_Y0u7KFwiqN,
  _30_trainingVideoService_igHKAiLGQX,
  _30_userAuthenticationService_04IVNPbDxB,
  _31_s3UploadService_oft8u7vr56,
  auth_vT9JWWT9pN,
  broadcastsHelper_l5QX3KEtX1,
  flavorManager_64XkvarXep,
  fontawesome_cn2c4tOOHz,
  geolocation_ziVmBUOoHS,
  googleMaps_yobp94ocoP,
  googleTagManager_QmmW6lPxbX,
  tenantHostService_Grnhr0X0dS,
  timeProvider_Pwk6BtTnUw,
  toastService_F88m7nHGCc,
  vModelState_Uui3jHSKz0
]