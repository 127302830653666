// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type {
    AuthenticationResponse,
    AuthenticationCookieResponse,
    RefreshRequest,
    SignOutRequest,
} from '~/types/api/Psrm.ApiGateway.Core.Auth.UserAuthentication';
import type { VerifyPinAuthenticationRequest, VerifyTokenRequest } from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            userAuthenticationService: {
                authenticatePostFetcher(): ApiResponse<VerifyPinAuthenticationRequest,
                    AuthenticationResponse> {
                    // post /{tenant}/token
                    return $mobileApi.getPostFetcher('{tenant}/token');
                },
                refreshPostFetcher(): ApiResponse<RefreshRequest,
                    AuthenticationCookieResponse> {
                    // post /{tenant}/auth/refresh
                    return $mobileApi.getPostFetcher('{tenant}/auth/refresh');
                },
                signInPostFetcher(): ApiResponse<VerifyPinAuthenticationRequest,
                    AuthenticationCookieResponse> {
                    // post /{tenant}/auth/sign-in
                    return $mobileApi.getPostFetcher('{tenant}/auth/sign-in');
                },
                signOutPostFetcher(): ApiResponse<SignOutRequest,
                    AuthenticationCookieResponse> {
                    // post /{tenant}/auth/sign-out
                    return $mobileApi.getPostFetcher('{tenant}/auth/sign-out');
                },
                verifyTokenFetcher(): ApiResponse<VerifyTokenRequest,
                    unknown> {
                    // get /{tenant}/token/verify
                    return $mobileApi.getGetFetcher('{tenant}/token/verify');
                },
            },
        },
    };
});
