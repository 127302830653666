import type { AuthenticationCookieResponse, AuthenticationResponse } from '~/types/api/Psrm.ApiGateway.Core.Auth.UserAuthentication';
import type { VerifyPinAuthenticationRequest } from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';

export default defineNuxtPlugin(() => {
    const { $mobileApi, $userAuthenticationService } = useNuxtApp();
    const authCookieFetcher = $userAuthenticationService.signInPostFetcher();
    const authFetcher = $userAuthenticationService.authenticatePostFetcher();
    const verifyFetcher = $userAuthenticationService.verifyTokenFetcher();
    // 36581 - cookie auth fails for mgsecurity, mulligan, walmart
    let useTokenAuth = true;

    async function tokenAuth(request: VerifyPinAuthenticationRequest) {
        const response = await authFetcher.fetch(request);
        $mobileApi.reconfigure({
            accessToken: response.accessToken,
        });
        return response;
    }

    return {
        provide: {
            authService: {
                async auth(request: VerifyPinAuthenticationRequest) {
                    let response: AuthenticationCookieResponse | AuthenticationResponse;
                    if (useTokenAuth) {
                        response = await tokenAuth(request);
                    }
                    else {
                        response = await authCookieFetcher.fetch(request);
                    }

                    try {
                        verifyFetcher.fetch({});
                    }
                    catch (error) {
                        console.error('Cookie auth is not functioning.', error);
                        useTokenAuth = true;
                        response = await tokenAuth(request);
                    }

                    const userInfo = currentUser();
                    userInfo.authenticate(response);
                    return response;
                },
                get processing() {
                    return computed<boolean>(() => authCookieFetcher.processing || authFetcher.processing || verifyFetcher.processing);
                },
            },
        },
    };
});
