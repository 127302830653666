<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import type { NuxtError, PageMeta } from '#app';

const { $flavorManager } = useNuxtApp();
type ContentType = {
    title: string;
    body: string;
    icon?: string;
};

const $props = defineProps<{
    error: NuxtError;
}>();

console.error($props.error);

const content = computed<ContentType>(() => {
    switch (($props.error.data as { type: string }).type) {
        case 'BrowserUnsupported':
            return { title: 'Browser version not supported', body: 'Your browser is outdated. To ensure a safe and optimal experience, please update your browser to the latest version.' };
        case 'HostNotFound':
            return { title: 'Page not found', body: 'The page you\'re looking for doesn\'t exist.', icon: 'error-404.svg' };
        default:
            return { title: 'Something went wrong', body: 'We had an unexpected error. Please try again.' };
    }
});
const flavorLayout = computed<PageMeta['layout']>(() => `flavor-${$flavorManager?.flavor ?? 'gfx'}`);
</script>

<template>
    <div>
        <NuxtLayout :name="flavorLayout" no-container>
            <NuxtLayout name="anonymous" no-header>
                <div class="d-flex flex-column align-items-center gap-3">
                    <img
                        :src="`/icons/${content.icon ?? 'error-other.svg'}`"
                        alt=""
                        class="w-50"
                    >
                    <h1 class="h5 text-danger">{{ content.title }}</h1>
                    <div>{{ content.body }}</div>
                </div>
            </NuxtLayout>
        </NuxtLayout>
    </div>
</template>
