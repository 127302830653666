// Specifically for settingsProvider, so I didn't put this in utils/. I don't want this auto imported into every Nuxt file.
import type { RouteLocationNormalizedLoaded } from '#vue-router';
import type { AppSettings, TenantOption } from '~/types/AppSettings';

const hostOptions: Readonly<AppSettings>[] = [
    {
        hostname: 'gps.securitime.com',
        mobileApiUrl: 'https://api2.securitime.com/mobile',
        s3UploadServiceUrl: 'https://api2.securitime.com/s3uploads',
        webSocketUrl: 'wss://ws.securitime.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.securitime.com',
        portalsAppSyncHttpDomain: 'portals-ws.securitime.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: [],
        customerKey: undefined,
        flavor: 'gfx',
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: false,
        gtmTag: 'GTM-TN4R8SKN',
    },
    {
        hostname: 'mobile.protossecurity.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['protos'],
        customerKey: 'Psrm-Protos',
        flavor: 'protos',
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: true,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'mobile.protosconnect.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['protos', 'gfx'],
        customerKey: undefined,
        rootIsIvrRouter: true,
        ivrExcludedCustomerKeys: ['mulligan-security', 'mg-security-services'],
        rootIsMsp: false,
        flavor: 'protos-connect',
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: false,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'walmartgps.securitimewfx.com',
        mobileApiUrl: 'https://api2.securitime.com/mobile',
        s3UploadServiceUrl: 'https://api2.securitime.com/s3uploads',
        webSocketUrl: 'wss://ws.securitime.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.securitime.com',
        portalsAppSyncHttpDomain: 'portals-ws.securitime.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['walmart'],
        customerKey: 'walmart',
        alwaysMsp: true,
        flavor: 'securitime',
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: false,
        gtmTag: 'GTM-TN4R8SKN',
    },
    {
        hostname: 'gps.securitimewfx.com',
        mobileApiUrl: 'https://api2.securitime.com/mobile',
        s3UploadServiceUrl: 'https://api2.securitime.com/s3uploads',
        webSocketUrl: 'wss://ws.securitime.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.securitime.com',
        portalsAppSyncHttpDomain: 'portals-ws.securitime.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['wfx'],
        customerKey: 'Psrm-WFXPilot',
        alwaysMsp: true,
        flavor: 'securitime',
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: false,
        gtmTag: 'GTM-TN4R8SKN',
    },
    {
        hostname: 'mobile.securitime.com',
        mobileApiUrl: 'https://api2.securitime.com/mobile',
        s3UploadServiceUrl: 'https://api2.securitime.com/s3uploads',
        webSocketUrl: 'wss://ws.securitime.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.securitime.com',
        portalsAppSyncHttpDomain: 'portals-ws.securitime.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['walmart', 'wfx'],
        customerKey: undefined,
        rootIsIvrRouter: true,
        rootIsMsp: true,
        flavor: 'securitime',
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: false,
        gtmTag: 'GTM-TN4R8SKN',
    },
    {
        hostname: 'mobile.bluestarsecurityllc.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['gfx'],
        customerKey: 'blue-star-security',
        flavor: 'gfx',
        logo: {
            header: 'images/bluestar/logo.svg',
            splash: 'images/bluestar/logo-ondark.svg',
            splashWidth: '265px',
            faviconFolder: 'images/bluestar',
        },
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: true,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'mobile-ny.squadsecurity.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['gfx'],
        customerKey: 'squad',
        flavor: 'gfx',
        logo: {
            header: 'images/squad/logo.svg',
            splash: 'images/squad/logo-ondark.svg',
            splashWidth: '227px',
            faviconFolder: 'images/squad',
        },
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: true,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'mobile-ca.squadsecurity.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['gfx'],
        customerKey: 'squad-ca',
        flavor: 'gfx',
        logo: {
            header: 'images/squad/logo.svg',
            splash: 'images/squad/logo-ondark.svg',
            splashWidth: '227px',
            faviconFolder: 'images/squad',
        },
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: true,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'mobile.offdutyservices.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['gfx'],
        customerKey: 'off-duty-services',
        flavor: 'gfx',
        logo: {
            header: 'images/ods/logo.svg',
            splash: 'images/ods/logo-ondark.svg',
            splashWidth: '252px',
            faviconFolder: 'images/ods',
        },
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: true,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'mobile-can.offdutyservices.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['gfx'],
        customerKey: 'forsythe-security',
        flavor: 'gfx',
        logo: {
            header: 'images/ods/logo.svg',
            splash: 'images/ods/logo-ondark.svg',
            splashWidth: '252px',
            faviconFolder: 'images/ods',
        },
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: true,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'mobile.mulligansecurity.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['gfx'],
        customerKey: 'mulligan-security',
        flavor: 'gfx',
        logo: {
            header: 'images/mulligan/logo.png',
            splash: 'images/mulligan/logo-ondark.png',
            splashWidth: '285px',
            faviconFolder: 'images/mulligan',
        },
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: false,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'mobile.mgsecurityservices.com',
        mobileApiUrl: 'https://api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-kgblehmakrdzpiwv4atwlzyh7e',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['gfx'],
        customerKey: 'mg-security-services',
        flavor: 'gfx',
        logo: {
            header: 'images/mgsecurity/logo.png',
            splash: 'images/mgsecurity/logo-ondark.png',
            splashWidth: '285px',
            faviconFolder: 'images/mgsecurity',
        },
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: false,
        gtmTag: 'GTM-KVM2DHJS',
    },
    // Test
    {
        hostname: 'test-gps.protossecurity.com',
        mobileApiUrl: 'https://test-api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://test-api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://test-ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://test-portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'test-portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-3qflbmiyzrezthyaxk4xewdjxm',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: ['test'],
        customerKey: 'protos-test',
        flavor: 'gfx',
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/test/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: false,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: /((qa|dev)[0-9]+)-gps\.protossecurity\.com/,
        mobileApiUrl: 'https://{prefix}.test-api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://{prefix}.test-api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://{prefix}.test-ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://test-portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'test-portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-3qflbmiyzrezthyaxk4xewdjxm',
        appSyncMobileChannelPrefix: '/mobile/{prefix}',
        tenants: [],
        customerKey: undefined,
        flavor: 'gfx',
        walkMeUrl: 'https://cdn.walkme.com/users/550bf3307be3442abfb7f77fa17371ef/test/walkme_550bf3307be3442abfb7f77fa17371ef_https.js',
        redirectToProtosConnect: true,
        gtmTag: 'GTM-KVM2DHJS',
    },
    {
        hostname: 'localhost',
        mobileApiUrl: 'https://test-api2.protossecurity.com/mobile',
        s3UploadServiceUrl: 'https://test-api2.protossecurity.com/s3uploads',
        webSocketUrl: 'wss://test-ws.protossecurity.com',
        portalsAppSyncRealtimeUrl: 'wss://test-portals-ws.protossecurity.com',
        portalsAppSyncHttpDomain: 'test-portals-ws.protossecurity.com',
        portalsAppSyncApiKey: 'da2-3qflbmiyzrezthyaxk4xewdjxm',
        appSyncMobileChannelPrefix: '/mobile/live',
        tenants: [],
        customerKey: undefined,
        flavor: 'gfx',
        // tenant: 'test',
        walkMeUrl: null,
        redirectToProtosConnect: false,
        gtmTag: 'GTM-KVM2DHJS',
    },
];

const hostRedirects: Record<string, string> = {
    'gps.protossecurity.com': 'msp-mobile.protossecurity.com',
    'mobile.squadsecurity.com': 'mobile-ny.squadsecurity.com',
    'msp-mobile.squadsecurity.com': 'msp-mobile-ny.squadsecurity.com',
    'redirect.test-gps.protossecurity.com': 'test-gps.protossecurity.com',
};

/**
 * Retrieves the application settings based on the provided hostname.
 *
 * The function determines the appropriate settings by checking the hostname against
 * various conditions, including whether it starts with 'msp-', matches a subdomain,
 * or matches a regular expression.
 *
 * @param hostname - The hostname to retrieve settings for.
 * @returns The application settings if a match is found, otherwise `undefined`.
 */
export function getAppSettings(hostname: string): AppSettings | undefined {
    const isMsp = hostname.startsWith('msp-');
    const trimmedHostname = isMsp ? hostname.substring(4) : hostname;
    const settings = hostOptions.find(x => typeof x.hostname === 'string' && x.hostname === trimmedHostname);
    if (settings) {
        return {
            ...settings,
            alwaysMsp: (settings.rootIsMsp || settings.alwaysMsp || isMsp),
        };
    }

    const subDomainSettings = hostOptions.find(x => typeof x.hostname === 'string' && x.hostname === trimmedHostname.substring(trimmedHostname.indexOf('.') + 1));
    if (subDomainSettings) {
        return {
            ...subDomainSettings,
            alwaysMsp: (subDomainSettings.alwaysMsp || isMsp),
            customerKey: trimmedHostname.substring(0, trimmedHostname.indexOf('.')),
        };
    }

    const regexMatchedSettings = hostOptions.find(x => x.hostname instanceof RegExp && trimmedHostname.match(x.hostname));
    if (regexMatchedSettings) {
        const environmentPrefix = trimmedHostname.match(regexMatchedSettings.hostname);
        if (environmentPrefix && environmentPrefix.length > 0) {
            const settings = { ...regexMatchedSettings,
                hostname: (regexMatchedSettings.hostname as RegExp).exec(trimmedHostname)![0],
                alwaysMsp: regexMatchedSettings.alwaysMsp || isMsp,
                customerKey: regexMatchedSettings.customerKey ?? trimmedHostname.substring(0, trimmedHostname.indexOf('.')),
                mobileApiUrl: regexMatchedSettings.mobileApiUrl.replace('{prefix}', environmentPrefix[1]),
                s3UploadServiceUrl: regexMatchedSettings.s3UploadServiceUrl.replace('{prefix}', environmentPrefix[1]),
                webSocketUrl: regexMatchedSettings.webSocketUrl.replace('{prefix}', environmentPrefix[1]),
                appSyncMobileChannelPrefix: regexMatchedSettings.appSyncMobileChannelPrefix.replace('{prefix}', environmentPrefix[1]),
            };
            return settings;
        }
        return regexMatchedSettings;
    }

    if (hostname.includes('ngrok')) {
        return hostOptions.find(x => x.hostname === 'localhost');
    }
}

/**
 * Checks if the current hostname has a corresponding redirect URL in the `hostRedirects` object.
 * If a redirect URL is found, the function will replace the current window.location with the new URL.
 */
export function checkForRedirect() {
    if (hostRedirects[window.location.hostname]) {
        window.location.replace(`https://${hostRedirects[window.location.hostname]}`);
    }
}

/**
 * If `setting` is in query string, store it in sessionStorage and return the value.
 * @param setting
 * @returns value from query or sessionStorage
 */
function testGetSetting(setting: string, route: Pick<RouteLocationNormalizedLoaded, 'query'>): string | undefined {
    const key = `emulate.${setting}`;
    if (setting in route.query) {
        let value = route.query[setting];
        if (Array.isArray(value)) {
            value = value[0];
        }
        if (!value) {
            sessionStorage.removeItem(key);
            return undefined;
        }
        sessionStorage.setItem(key, value);
        return value;
    }
    return sessionStorage.getItem(key) ?? undefined;
}

/**
 * Retrieves the emulation settings for the application.
 *
 * This function checks for emulation settings for the host and tenant.
 * If neither are found, it returns `undefined`. If a tenant emulation
 * setting is found, it returns the application settings with the
 * emulated tenant. Otherwise, it returns the application settings with
 * the emulated host.
 *
 * @returns {AppSettings | undefined} The emulation settings or `undefined` if no emulation is set.
 */
export function getEmulation(route: Pick<RouteLocationNormalizedLoaded, 'query'>): AppSettings | undefined {
    const emulateHost = testGetSetting('host', route);
    const emulateTenant = testGetSetting('tenant', route);
    if (!emulateHost && !emulateTenant) {
        return undefined;
    }

    if (emulateTenant) {
        const appSettings = getAppSettings(emulateHost ?? window.location.hostname);
        if (!appSettings) {
            console.error(`host not configured: ${emulateHost ?? window.location.hostname}`);
            return undefined;
        }
        return {
            ...appSettings,
            tenants: [emulateTenant as TenantOption],
        };
    }
    return getAppSettings(emulateHost!);
}
