// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type { IdentitySessionResponse, GetSessionRequest } from '~/types/api/Psrm.Mobile.Api.Contract.Identity';
import type { TrustStampVerificationResponse } from '~/types/api/Psrm.ExternalIntegrations.TrustStamp.Client.Models';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            identityService: {
                getSessionFetcher(): ApiResponse<GetSessionRequest,
                    IdentitySessionResponse> {
                    // get /{tenant}/identity/session
                    return $mobileApi.getGetFetcher('{tenant}/identity/session');
                },
                handleVerificationResultPostFetcher(): ApiResponse<TrustStampVerificationResponse,
                    unknown> {
                    // post /{tenant}/identity/session/result
                    return $mobileApi.getPostFetcher('{tenant}/identity/session/result');
                },
            },
        },
    };
});
