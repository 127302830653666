import type { Flavor } from './flavorManager';
import { checkForRedirect, getAppSettings, getEmulation } from '~/utilsPriv/configuration';
import type { AppSettingsProvided, TenantOption } from '~/types/AppSettings';
import type { NuxtApp } from '#app';

const customerKeyFlavorMap: Record<string, Flavor> = {
    'protos-test': 'securitime',
    'securitime-demo': 'securitime',
    'ivr-router': 'securitime',
};

function validateOrInitOnMount(nuxt: NuxtApp) {
    nuxt.hook('app:mounted', async () => {
        const { $tenantHostService, $settings } = useNuxtApp();
        if ($settings.tenant) {
            await $tenantHostService.validateHostname();
        }
        else if ($settings.customerKey) {
            await $tenantHostService.initTenantFromCustomerKey($settings.customerKey);
        }
        else {
            // Doing this after all plugins initialize to prevent errors.
            throw createError({
                statusCode: 404,
                statusMessage: 'Neither tenant nor customerKey were available for this host.',
                message: 'Neither tenant nor customerKey were available for this host.',
                data: {
                    type: 'HostNotFound',
                },
            });
        }
    });
}

// TODO Might be a nuxt bug, but when I change .env locally, it doesn't change my config. Have to kill and start nuxt again.
export default defineNuxtPlugin((nuxt) => {
    const route = useRoute();
    const publicConfig = useRuntimeConfig().public;

    checkForRedirect();

    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname.includes('ngrok');
    const isTest = /(test|(qa|dev)[0-9]+)-gps\.protossecurity\.com$/.test(window.location.host);

    const emulate = isLocalhost || isTest ? getEmulation(route) : undefined;
    const appSettings = emulate ?? getAppSettings(window.location.hostname);
    if (!appSettings) {
        console.error(`host not configured: ${window.location.hostname}`);
        throw new Error(`host not configured: ${window.location.hostname}`);
    }

    const customerKey = publicConfig.customerKey || appSettings.customerKey;

    const settings: AppSettingsProvided = reactive({
        ...appSettings,
        rootIsIvrRouter: undefined,
        rootIsMsp: undefined,
        alwaysMsp: undefined,
        host: appSettings.hostname.toString(),
        tenants: appSettings.tenants,
        tenant: appSettings.tenants.length === 1 && appSettings.tenants[0] !== 'ivr-router' ? appSettings.tenants[0] : undefined,
        s3UploadServiceUrl: publicConfig.s3UploadServiceUrl || appSettings.s3UploadServiceUrl,
        mobileApiUrl: publicConfig.mobileApiUrl || appSettings.mobileApiUrl,
        webSocketUrl: publicConfig.webSocketUrl || appSettings.webSocketUrl,
        isMsp: Boolean(publicConfig.mspOverride || appSettings.alwaysMsp),
        env: isLocalhost ? 'dev' : isTest ? 'test' : 'production',
        isIvrRouter: Boolean(customerKey === 'ivr-router' || (customerKey === undefined && appSettings.rootIsIvrRouter)),
    });

    if (emulate) {
        window.settings = settings;
    }

    if (isLocalhost) {
        settings.customerKey = settings.customerKey ?? publicConfig.customerKey;
        if (!emulate && publicConfig.tenant !== '') {
            settings.tenant = publicConfig.tenant as TenantOption ?? settings.tenant;
        }
    }

    if (settings.isIvrRouter && route.query.customerKey) {
        const customerKey = Array.isArray(route.query.customerKey) ? route.query.customerKey[0] : route.query.customerKey;
        if (customerKey) {
            settings.customerKey = customerKey;
        }
    }

    if (!isLocalhost || publicConfig.flavor === '') {
        if (settings.customerKey && customerKeyFlavorMap[settings.customerKey]) {
            settings.flavor = customerKeyFlavorMap[settings.customerKey];
        }
    }

    if (!settings.isIvrRouter) {
        validateOrInitOnMount(nuxt as NuxtApp);
    }

    return {
        provide: {
            settings,
        },
    };
});
