import type { ModelStateValue } from '@psrm/base-service';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('modelState', {
        updated(el, binding, vNode) {
            const className = 'has-model-state-error';
            const element = el as HTMLElement;
            if (element) {
                const bindingAs = binding.value as ModelStateValue | undefined;
                if (bindingAs && bindingAs.length > 0) {
                    if (!element.classList.contains(className)) {
                        element.classList.add(className);
                    }
                    if (element.parentElement) {
                        const scopedErrorClass = `model-state-error-${vNode.scopeId}`;
                        const errorMessage = bindingAs.join(', ');
                        const existingErrorElements = element.parentElement.getElementsByClassName(scopedErrorClass);
                        if (existingErrorElements.length > 0) {
                            existingErrorElements[0].textContent = errorMessage;
                        }
                        else {
                            const errorMessageElement = document.createElement('span');
                            errorMessageElement.classList.add('model-state-error-message');
                            errorMessageElement.classList.add('text-danger');
                            errorMessageElement.classList.add(scopedErrorClass);
                            errorMessageElement.textContent = errorMessage;
                            element.insertAdjacentElement('afterend', errorMessageElement);
                        }
                    }
                }
                else if (element.classList.contains(className)) {
                    element.classList.remove(className);
                    if (element.parentElement) {
                        const scopedErrorClass = `model-state-error-${vNode.scopeId}`;
                        const existingErrorElements = element.parentElement.getElementsByClassName(scopedErrorClass);
                        if (existingErrorElements.length > 0) {
                            for (const existingError of existingErrorElements) {
                                existingError.remove();
                            }
                        }
                    }
                }
            }
        },
    });
});
