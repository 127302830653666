import type { Dayjs } from 'dayjs';

// Computed won't auto update based on time. 🙁
export default defineNuxtPlugin(() => {
    const currentMinute = ref<Dayjs>(dayjs().startOf('minute'));
    const currentSecond = ref<Dayjs>(dayjs().startOf('second'));
    updateMinute();
    updateSecond();

    function updateMinute() {
        currentMinute.value = dayjs().startOf('minute');
        setTimeout(updateMinute, 60000 - (new Date().getTime() % 60000));
    }

    function updateSecond() {
        currentSecond.value = dayjs().startOf('second');
        setTimeout(updateSecond, 1000 - (new Date().getTime() % 1000));
    }

    return {
        provide: {
            timeProvider: {
                currentMinute: readonly(currentMinute),
                /** WARNING: Will cause frequent updates if used in computed. */
                currentSecond: readonly(currentSecond),
            },
        },
    };
});
