<script setup lang="ts">
import { $fetch } from 'ofetch';
/*
 * Nuxt dev server automatically reloads changes, so to test this locally:
 * 1. #> nuxt build
 * 2. #> node .output/server/index.mjs
 * 3. Open http://localhost:3000
 * 4. Update version in package.json
 * 5. #> nuxt build
 *    (This will eventually cause node in step 2 to error, making the requests not work while it continues to build.)
 * 6. #> node .output/server/index.mjs
 *
 * Once it starts up and the request succeeds the browser in step 3 will show this popup.
 */
const runtimeConfig = useRuntimeConfig();
const pollInterval = 60000; // 60000
let intervalId: NodeJS.Timeout | undefined;
const clientVersion = runtimeConfig.public.clientVersion;
const serverVersion = ref(clientVersion);

async function fetchServerVersion() {
    const response = await $fetch(runtimeConfig.app.baseURL ?? '/', { headers: { 'Cache-Control': 'no-cache' } });
    if (typeof response !== 'string') {
        throw new TypeError('Server did not return a string.');
    }

    const m = /clientVersion:"([\d]+(?:\.[\d]+)+[\d\\.\w-]*)"/;
    if (m.exec(response)?.[1]) {
        serverVersion.value = m.exec(response)?.[1] as string;
    }
}

onMounted(() => {
    fetchServerVersion();
    intervalId = setInterval(fetchServerVersion, pollInterval);
});

onBeforeUnmount(() => {
    clearInterval(intervalId);
});

function reload() {
    location.reload();
}
</script>

<template>
    <BModal
        cancel-title="Continue Working"
        ok-title="Refresh Now"
        hide-header
        cancel-variant="link"
        no-stacking
        centered
        no-close-on-backdrop
        :model-value="serverVersion !== clientVersion"
        @ok="reload"
    >
        <div class="update-modal-content">
            <img
                src="/images/update-available.svg"
                alt=""
            >
            <h2>Update available</h2>
            <p>We recommend reloading the application now. If you have unfinished work, you can continue, but you may encounter issues.</p>
            <p class="version-info">Current Version: {{ clientVersion }}, New Version: {{ serverVersion }}</p>
        </div>
    </BModal>
</template>

<style scoped>
    .update-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.5rem;

        h2 {
            font-size: 1.75rem;
            color: #333;
            margin: 1rem 0;
        }

        p {
            text-align: center;
            color: #555;
        }

        .version-info {
            color: #888;
            font-style: italic;
            font-size: 0.85rem;
        }
    }
</style>
