import { Loader } from '@googlemaps/js-api-loader';

export default defineNuxtPlugin(() => {
    function initLib() {
        const publicConfig = useRuntimeConfig().public;
        const loader = new Loader({
            apiKey: publicConfig.googleMapsApiKey,
            version: 'weekly',
            libraries: ['maps'],
        });
        return {
            maps: loader.importLibrary('maps'),
            routes: loader.importLibrary('routes'),
            marker: loader.importLibrary('marker'),
            geocoding: loader.importLibrary('geocoding'),
        };
    }
    let lib: ReturnType<typeof initLib> | null = null;

    return {
        provide: {
            googleMaps: {
                get lib() {
                    if (lib === null) {
                        lib = initLib();
                    }
                    return lib;
                },
                async initMap(el: HTMLElement) {
                    return new (await this.lib.maps).Map(el, {
                        center: { lat: 37.7699298, lng: -122.4469157 },
                        zoom: 14,
                    });
                },
            },
        },
    };
});
