import { FetchError } from 'ofetch';
import type { ValidateHostnameRequest } from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';
import type { LookupByPinRequest } from '~/types/api/Psrm.Mobile.Api.Contract.TenantLookup';
import type { TenantOption } from '~/types/AppSettings';
import type { TenantInitResult } from '~/types/TenantInitResult';

export default defineNuxtPlugin(() => {
    let validHostnameRequest: ValidateHostnameRequest = {};

    return {
        provide: {
            tenantHostService: {
                async validateHostname() {
                    const { $ivrWebService, $settings } = useNuxtApp();
                    const domain = $settings.host;
                    const fetcher = $ivrWebService.validateHostnameFetcher();
                    const request: ValidateHostnameRequest = {
                        hostName: domain,
                    };

                    if ($settings.customerKey) {
                        request.customerKey = $settings.customerKey;
                    }

                    if (validHostnameRequest.customerKey === request.customerKey && validHostnameRequest.hostName === request.hostName) {
                        // url has already been validated successfully
                        return true;
                    }

                    try {
                        await fetcher.fetch(request);
                        // TODO publicConfig.logo = response.logo;
                        validHostnameRequest = request;
                    }
                    catch (e: unknown) {
                        if (e as { status: number; statusMessage: string; message: string } !== undefined) {
                            const error = e as { status: number; statusMessage: string; message: string };
                            throw createError({
                                statusCode: error.status ?? 500,
                                statusMessage: error.statusMessage ?? 'Application Error',
                                message: error.message ?? 'A server error occurred.',
                                data: {
                                    type: error.status === 404 ? 'HostNotFound' : null,
                                    innerError: e,
                                },
                            });
                        }
                    }
                    return true;
                },
                async initTenantFromCustomerKey(customerKey: string) {
                    const { $tenantLookupService, $settings } = useNuxtApp();
                    const fetcher = $tenantLookupService.getTenant2Fetcher();
                    try {
                        await fetcher.fetch({ customerKey });
                    }
                    catch (e: unknown) {
                        if (e as { status: number; statusMessage: string; message: string } !== undefined) {
                            const error = e as { status: number; statusMessage: string; message: string };
                            throw createError({
                                statusCode: error.status ?? 500,
                                statusMessage: error.statusMessage ?? 'Application Error',
                                message: error.message ?? 'A server error occurred.',
                                data: {
                                    type: error.status === 404 ? 'HostNotFound' : null,
                                    innerError: e,
                                },
                            });
                        }
                    }
                    if (!fetcher.response?.tenantName) {
                        throw createError(`tenant lookup did not return string: ${typeof fetcher.response?.tenantName}`);
                    }
                    $settings.tenant = fetcher.response.tenantName as TenantOption;
                    // Wait for mobileApiService to update based on `watch($settings.tenant, ...)`
                    nextTick(() => this.validateHostname());
                },
                async initTenantFromPin(pinInfo: LookupByPinRequest): Promise<TenantInitResult> {
                    const { $tenantLookupService, $settings } = useNuxtApp();
                    let takeoverPossible = false;
                    if ($settings.tenant || !$settings.isIvrRouter) {
                        return { status: 'success' };
                    }
                    try {
                        const lookupByPinFetcher = $tenantLookupService.lookupByPinFetcher();
                        const response = await lookupByPinFetcher.fetch(pinInfo);
                        takeoverPossible = response.employeeTakeoverPossible;
                        if (takeoverPossible) {
                            return { status: 'takeoverPossible', message: response.employeeTakeoverMessage ?? '' };
                        }
                        $settings.tenant = response.tenantName as TenantOption;
                        $settings.customerKey = response.customerKey!;
                    }
                    catch (e: unknown) {
                        if (e instanceof FetchError && typeof e.data?.errorMessage === 'string') {
                            return {
                                status: 'error',
                                message: e.data.errorMessage,
                            };
                        }
                        else {
                            throw createError('Failed to init tenant.');
                        }
                    }
                    // Wait for mobileApiService to update based on `watch($settings.tenant, ...)`
                    nextTick(() => this.validateHostname());

                    return { status: 'success' };
                },
            },
        },
    };
});
