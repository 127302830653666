// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type { GetProvisioningInfoResponse, GetProvisioningInfoRequest } from '~/types/api/Psrm.Mobile.Api.Contract.Provisioning';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            provisioningService: {
                getProvisioningInfoFetcher(): ApiResponse<GetProvisioningInfoRequest,
                    GetProvisioningInfoResponse> {
                    // get /provision
                    return $mobileApi.getGetFetcher('provision');
                },
            },
        },
    };
});
