const flavorOptions = ['gfx', 'protos', 'securitime', 'protos-connect'] as const;
export type Flavor = (typeof flavorOptions)[number];
export interface ImageWithWidth {
    src: string;
    width?: string;
}

export default defineNuxtPlugin(() => {
    const { $settings } = useNuxtApp();
    const route = useRoute();
    const baseURL = useRuntimeConfig().app.baseURL ?? '/';
    const flavorString = Array.isArray(route.query.flavor) ? route.query.flavor[0] : route.query.flavor;

    function isValid(flavor: string): boolean {
        return !!flavorOptions.find(f => f === flavor.toLowerCase());
    }

    if ('flavor' in route.query) {
        if (!flavorString) {
            delete sessionStorage.flavor;
        }
        else if (isValid(flavorString)) {
            sessionStorage.flavor = flavorString.toLowerCase();
        }
        else {
            console.warn(`Flavor not found: ${flavorString}`);
        }
        // If we wanted to remove the query string after use, we'll have to do so in middleware.
    }

    return {
        provide: {
            flavorManager: {
                get flavor(): Flavor {
                    if (sessionStorage.flavor) {
                        return sessionStorage.flavor;
                    }
                    return $settings.flavor;
                },
                headerLogo() {
                    if ($settings.logo) {
                        return `${baseURL}` + $settings.logo.header;
                    }
                    switch (this.flavor) {
                        case 'gfx':
                            return `${baseURL}` + 'flavors/gfx/logo.svg';
                        case 'protos':
                            return `${baseURL}` + 'flavors/protos/logo.svg';
                        case 'securitime':
                            return `${baseURL}` + 'flavors/securitime/logo.svg';
                        case 'protos-connect':
                            return `${baseURL}` + 'flavors/protos-connect/logo.svg';
                    }
                },
                splashLogo(): ImageWithWidth {
                    if ($settings.logo) {
                        return { src: `${baseURL}` + $settings.logo.splash, width: $settings.logo.splashWidth };
                    }
                    switch (this.flavor) {
                        case 'gfx':
                            return { src: `${baseURL}` + 'flavors/gfx/logo-white.svg', width: '200px' };
                        case 'protos':
                            return { src: `${baseURL}` + 'flavors/protos/logo-h.svg', width: '305px' };
                        case 'securitime':
                            return { src: `${baseURL}` + 'flavors/securitime/logo-white.svg', width: '208px' };
                        case 'protos-connect':
                            return { src: `${baseURL}` + 'flavors/protos-connect/logo.svg', width: '208px' };
                    }
                },
                themeColor(): `#${string}` {
                    switch (this.flavor) {
                        case 'gfx':
                            return '#913e1d';
                        case 'protos':
                            return '#005e2e';
                        case 'securitime':
                            return '#3fa4b0';
                        case 'protos-connect':
                            return '#335290';
                    }
                },
            },
        },
    };
});
