import type { GetPinInfoResponse_ScheduleClass_ScheduleStatusType as ScheduleStatusType } from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';

interface ScheduleStatusMonitor {
    scheduleStatusType: ScheduleStatusType | null;
}

let eventFilter = '';
let socket: WebSocket | null = null;
export const scheduleStatusMonitor = defineStore('scheduleStatusMonitor', {
    state: (): ScheduleStatusMonitor => {
        return {
            scheduleStatusType: null,
        };
    },
    actions: {
        setScheduleStatus(scheduleStatus: ScheduleStatusType) {
            this.$state.scheduleStatusType = scheduleStatus;
        },
        tryStart() {
            const user = currentUser();
            if ((socket && socket.readyState === WebSocket.OPEN) || !user.pinInfo?.schedule?.scheduleUuid) {
                return;
            }

            const { $settings } = useNuxtApp();
            eventFilter = `scheduleUuid:${user.pinInfo?.schedule?.scheduleUuid}`;
            socket = new WebSocket(`${$settings.webSocketUrl}?eventFilter=${encodeURIComponent(eventFilter)}`);
            const eventTypesToProcess = ['PunchCreateEvent', 'PunchDeleteEvent', 'ScheduleStatusChangeEvent'];
            socket.onmessage = async (event) => {
                const eventData = JSON.parse(event.data);
                if (await user.isAuthenticated()
                    && user.pinInfo?.schedule?.scheduleUuid === eventData.detail?.scheduleUuid
                    && eventTypesToProcess.includes(eventData.detailType)) {
                    user.reloadPinInfo();
                }
            };

            socket.onclose = () => {
                this.tryStart();
            };

            socket.onerror = (err) => {
                console.error(err);
            };

            setTimeout(() => {
                this.stop();
            }, 300000); // kill after 5 minutes to ensure graceful close (need to send eventFilter)
        },
        stop() {
            if (socket) {
                socket.close(1000, eventFilter);
            }
        },
    },
});
