import { $fetch } from 'ofetch';
import type { GetFileResponseRows } from '~/types/S3UploadServiceTypes';

export default defineNuxtPlugin(() => {
    const { $settings } = useNuxtApp();
    return {
        provide: {
            s3UploadService: {
                getFileUploadUrls(numFiles: number) {
                    return $fetch<Array<GetFileResponseRows>>($settings.s3UploadServiceUrl, {
                        method: 'POST',
                        body: { numFiles },
                    });
                },
                uploadToS3(uploadUrl: string, fileData: File) {
                    const headers: HeadersInit = {
                        'Content-Type': 'application/octet-stream',
                        // Authorization: undefined,
                    };
                    return $fetch(uploadUrl, {
                        method: 'PUT',
                        headers,
                        body: fileData,
                    });
                },
            },
        },
    };
});
