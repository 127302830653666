import type { ToastMessage, ToastMessageWithKey } from '~/types/ToastMessage';

export default defineNuxtPlugin(() => {
    const toasts = ref([] as ToastMessageWithKey[]);
    const messageLifetimeMs = 5000;

    return {
        provide: {
            toastService: {
                addToast(toast: ToastMessage) {
                    const toastWithKey = addKey(toast);
                    toasts.value.push(toastWithKey);
                    setTimeout(() => {
                        const toastIndex = toasts.value.findIndex(t => t.key === toastWithKey.key);
                        if (toastIndex >= 0) {
                            toasts.value.splice(toastIndex, 1);
                        }
                    }, messageLifetimeMs);
                },
                dismissToast(toast: ToastMessageWithKey) {
                    const toastIndex = toasts.value.findIndex(t => t.key === toast.key);
                    if (toastIndex >= 0) {
                        toasts.value.splice(toastIndex, 1);
                    }
                },
                toasts,
            },
        },
    };
});
