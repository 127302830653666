// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type {
    AcknowledgeBroadcastsRequest,
    AcknowledgeBroadcastRequest,
    GetBroadcastMessagesForMobileResponse,
    GetBroadcastMessagesRequest,
    GetDomainInfoResponse,
    ValidateHostnameRequest,
    GetPinInfoResponse,
    GetPinInfoRequest,
    GetPostOrdersResponse,
    GetPostOrdersRequest,
    GetExternalProtosLinkedBroadcastsResponse,
    GetExternalProtosLinkedBroadcastsRequest,
    GetFlagForRedirectUrlResponse,
    GetFlagForRedirectUrlRequest,
} from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            ivrWebService: {
                acknowledgeBroadcastPostFetcher(): ApiResponse<AcknowledgeBroadcastRequest,
                    unknown> {
                    // post /{tenant}/ivr-web/acknowledge-broadcast
                    return $mobileApi.getPostFetcher('{tenant}/ivr-web/acknowledge-broadcast');
                },
                acknowledgeBroadcastsPostFetcher(): ApiResponse<AcknowledgeBroadcastsRequest,
                    unknown> {
                    // post /{tenant}/ivr-web/acknowledge-broadcasts
                    return $mobileApi.getPostFetcher('{tenant}/ivr-web/acknowledge-broadcasts');
                },
                getBroadcastMessagesFetcher(): ApiResponse<GetBroadcastMessagesRequest,
                    GetBroadcastMessagesForMobileResponse> {
                    // get /{tenant}/ivr-web/broadcasts
                    return $mobileApi.getGetFetcher('{tenant}/ivr-web/broadcasts');
                },
                getExternalProtosLinkedBroadcastsFetcher(): ApiResponse<GetExternalProtosLinkedBroadcastsRequest,
                    GetExternalProtosLinkedBroadcastsResponse> {
                    // get /{tenant}/ivr-web/protos-linked-broadcasts
                    return $mobileApi.getGetFetcher('{tenant}/ivr-web/protos-linked-broadcasts');
                },
                getFlagForRedirectUrlFetcher(): ApiResponse<GetFlagForRedirectUrlRequest,
                    GetFlagForRedirectUrlResponse> {
                    // get /{tenant}/ivr-web/should-redirect-url
                    return $mobileApi.getGetFetcher('{tenant}/ivr-web/should-redirect-url');
                },
                getPinInfoFetcher(): ApiResponse<GetPinInfoRequest,
                    GetPinInfoResponse> {
                    // get /{tenant}/ivr-web/pin-info
                    return $mobileApi.getGetFetcher('{tenant}/ivr-web/pin-info');
                },
                getPostOrdersFetcher(): ApiResponse<GetPostOrdersRequest,
                    GetPostOrdersResponse> {
                    // get /{tenant}/ivr-web/post-orders
                    return $mobileApi.getGetFetcher('{tenant}/ivr-web/post-orders');
                },
                validateHostnameFetcher(): ApiResponse<ValidateHostnameRequest,
                    GetDomainInfoResponse> {
                    // get /{tenant}/ivr-web/validate-hostname
                    return $mobileApi.getGetFetcher('{tenant}/ivr-web/validate-hostname');
                },
            },
        },
    };
});
