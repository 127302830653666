// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type { CreateTrainingVideoSessionRequest, UpdateTrainingVideoStatisticsRequest } from '~/types/api/Psrm.Mobile.Api.Contract.TrainingVideo';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            trainingVideoService: {
                createTrainingVideoSessionPostFetcher(): ApiResponse<CreateTrainingVideoSessionRequest,
                    unknown> {
                    // post /{tenant}/training-video/watch
                    return $mobileApi.getPostFetcher('{tenant}/training-video/watch');
                },
                updateTrainingVideoSessionPostFetcher(): ApiResponse<UpdateTrainingVideoStatisticsRequest & { uuid: string },
                    unknown> {
                    // post /{tenant}/training-video/watch/{request.uuid}
                    return $mobileApi.getPostFetcher('{tenant}/training-video/watch/{uuid}');
                },
            },
        },
    };
});
