import type { AuthenticationCookieResponse, AuthenticationResponse } from '@/types/api/Psrm.ApiGateway.Core.Auth.UserAuthentication';
import type { GetPinInfoResponse } from '@/types/api/Psrm.Mobile.Api.Contract.IvrWeb';
import type { TenantOption } from '~/types/AppSettings';

interface UserStateBase {
    accessToken: string | null; // fallback for token auth
    expiresAtUtc: string | null;
    uuid: ReturnType<typeof crypto.randomUUID>;
}

interface UserState extends UserStateBase {
    pinInfo: GetPinInfoResponse | null;
    isPerformingManualTakeover: boolean;
    takeoverEmployeePin: string | null;
}

interface UserStateLocalStorage extends UserStateBase {
    tenant: string | null;
}

const localStorageKey = 'cookieAuthInfoMobile';

function registerAuthenticationErrorHandler() {
    const { $mobileApi } = useNuxtApp();
    $mobileApi.registerGlobalErrorHandler(handleApiError);
}

function unregisterAuthenticationErrorHandler() {
    const { $mobileApi } = useNuxtApp();
    $mobileApi.unregisterGlobalErrorHandler(handleApiError);
}

async function handleApiError(httpResponseCode: number, _: unknown) {
    if (httpResponseCode === 401) {
        const userInfo = currentUser();
        if (userInfo) {
            await userInfo.deauthenticate();
        }

        navigateTo({
            path: '/auth',
            query: {
                reason: 'tokenExpiration',
            },
        });
    }
}

function tokenExpirationHandler() {
    const userInfo = currentUser();
    userInfo.deauthenticate('/auth?reason=tokenExpiration');
}

async function reloadPinInfo() {
    const userInfo = currentUser();
    if (!userInfo.expiresAtUtc) {
        console.debug('User is not authenticated, skipping reloadPinInfo()');
        return false;
    }
    const { $ivrWebService, $mobileApi } = useNuxtApp();
    await waitUntilResolved(() => $mobileApi.tenantIsDefined, 300, 15000);
    if ($mobileApi.tenantIsDefined === false) {
        console.warn('Tenant was not defined. Cannot get pin info.');
        return false;
    }
    const pinInfoFetcher = $ivrWebService.getPinInfoFetcher();
    try {
        userInfo.pinInfo = await pinInfoFetcher.fetch({});

        const scheduleStatus = scheduleStatusMonitor();
        if (userInfo.pinInfo) {
            scheduleStatus.setScheduleStatus(userInfo.pinInfo.schedule?.scheduleStatus ?? null);
        }
        scheduleStatus.tryStart();
    }
    catch (e) {
        console.error('Failed on reloadPinInfo', e);
        await userInfo.deauthenticate();
    }
}

let authExpirationTimer: NodeJS.Timeout | undefined;

export const currentUser = defineStore('currentUser', {
    state: (): UserState => {
        if (typeof crypto?.randomUUID !== 'function') {
            throw createError({
                statusCode: 400,
                data: {
                    type: 'BrowserUnsupported',
                },
            });
        }
        const state: UserState = {
            accessToken: null,
            expiresAtUtc: null,
            uuid: crypto.randomUUID(),
            pinInfo: null,
            isPerformingManualTakeover: false,
            takeoverEmployeePin: null,
        };
        try {
            const authItem: UserStateLocalStorage = JSON.parse(localStorage.getItem(localStorageKey) ?? '{}');
            if (authItem?.expiresAtUtc && authItem.expiresAtUtc > new Date().toISOString()) {
                const { $settings, $mobileApi } = useNuxtApp();
                state.accessToken = authItem.accessToken;
                state.expiresAtUtc = authItem.expiresAtUtc;
                state.uuid = authItem.uuid as ReturnType<typeof crypto.randomUUID>;

                if (!$settings.tenant) {
                    $settings.tenant = authItem.tenant as TenantOption ?? undefined;
                }

                if (authItem.accessToken) {
                    $mobileApi.reconfigure({
                        accessToken: authItem.accessToken,
                    });
                }

                registerAuthenticationErrorHandler();
                clearTimeout(authExpirationTimer);
                authExpirationTimer = setTimeout(tokenExpirationHandler, new Date(authItem.expiresAtUtc).getTime() - Date.now());
                setTimeout(reloadPinInfo, 1);
            }
        }
        catch (error) {
            console.error(error);
        }
        return state;
    },
    actions: {
        authenticate(authResponse: AuthenticationCookieResponse | AuthenticationResponse): void {
            if (!authResponse.expiresAtUtc) {
                throw new Error('AuthenticationCookieResponse.expiresAtUtc is expected to be set.');
            }
            const { $settings } = useNuxtApp();
            const accessToken = 'accessToken' in authResponse ? authResponse.accessToken ?? null : null;
            const auth: UserState = {
                accessToken: accessToken,
                expiresAtUtc: authResponse.expiresAtUtc,
                uuid: this.$state.uuid!, // This should be set on load and deauthenticate, so I don't think we need to reset it here
                pinInfo: null,
                isPerformingManualTakeover: false,
                takeoverEmployeePin: null,
            };

            if (!this.$state.uuid) {
                console.error('UUID expected to be set already.');
                auth.uuid = crypto.randomUUID();
            }

            this.$state = auth;

            const localStorageAuth: UserStateLocalStorage = {
                accessToken: auth.accessToken,
                expiresAtUtc: auth.expiresAtUtc,
                uuid: auth.uuid,
                tenant: $settings.tenant as TenantOption ?? null,
            };

            localStorage.setItem(localStorageKey, JSON.stringify(localStorageAuth));
            setTimeout(this.reloadPinInfo, 50);
            registerAuthenticationErrorHandler();
            clearTimeout(authExpirationTimer);
            authExpirationTimer = setTimeout(tokenExpirationHandler, new Date(authResponse.expiresAtUtc).getTime() - Date.now());
        },
        /** This will clear authentication info and also reloads the site. */
        async deauthenticate(path: string | false = '/auth') {
            const scheduleStatus = scheduleStatusMonitor();
            this.$state = {
                accessToken: null,
                expiresAtUtc: null,
                uuid: crypto.randomUUID(),
                pinInfo: null,
                isPerformingManualTakeover: false,
                takeoverEmployeePin: null,
            };
            localStorage.removeItem(localStorageKey);
            if (sessionStorage.getItem('sso')) {
                sessionStorage.removeItem('sso');
            }
            if (sessionStorage.getItem('firstEntry')) {
                sessionStorage.removeItem('firstEntry');
            }
            unregisterAuthenticationErrorHandler();

            clearTimeout(authExpirationTimer);
            scheduleStatus.stop();
            const { $userAuthenticationService, $settings, $mobileApi } = useNuxtApp();
            $mobileApi.reconfigure({
                accessToken: undefined,
            });
            if ($settings.tenant) {
                await $userAuthenticationService.signOutPostFetcher().fetch({});
            }
            if (path !== false) {
                // Lots of users keeping the app open indefinitely... Reload to get any app updates.
                reloadNuxtApp({
                    path,
                    ttl: 10000,
                });
                // If it was loaded less than ttl, it won't navigate
                navigateTo(path);
            }
        },
        async isAuthenticated() {
            if (!this.expiresAtUtc) {
                return false;
            }
            if (this.expiresAtUtc < new Date().toISOString()) {
                await this.deauthenticate();
                return false;
            }
            return true;
        },
        reloadPinInfo,
        getUserId() {
            if (this.pinInfo) {
                return this.pinInfo.employee?.id ? `e_${this.pinInfo.employee?.employeeUuid ?? this.pinInfo.employee?.id}` : `w_${this.pinInfo.workOrderId}`;
            }
            return null;
        },
        async performTakeover() {
            if (this.pinInfo?.employee?.pin) {
                const userPin = this.pinInfo?.employee?.pin;
                await this.deauthenticate(false);
                this.$state = {
                    accessToken: null,
                    expiresAtUtc: null,
                    uuid: crypto.randomUUID(),
                    pinInfo: null,
                    isPerformingManualTakeover: true,
                    takeoverEmployeePin: userPin,
                };
                navigateTo({
                    path: '/auth',
                });
            }
        },
    },
});
