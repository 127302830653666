// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type {
    LookupResponse,
    GetTenant2Request,
    LookupByPinResponse,
    LookupByPinRequest,
} from '~/types/api/Psrm.Mobile.Api.Contract.TenantLookup';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            tenantLookupService: {
                getTenant2Fetcher(): ApiResponse<GetTenant2Request,
                    LookupResponse> {
                    // get /tenant-lookup/{customerKey}
                    return $mobileApi.getGetFetcher('tenant-lookup/{customerKey}');
                },
                lookupByPinFetcher(): ApiResponse<LookupByPinRequest,
                    LookupByPinResponse> {
                    // get /tenant-lookup
                    return $mobileApi.getGetFetcher('tenant-lookup');
                },
            },
        },
    };
});
