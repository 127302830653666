// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type {
    GetBroadcastVideosResponse,
    GetBroadcastVideosByIdRequest,
    GetBroadcastVideosByPinResponse,
    GetBroadcastVideosByPinRequest,
} from '~/types/api/Psrm.Mobile.Api.Contract.IvrBroadcast';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            ivrBroadcastService: {
                getBroadcastVideosByIdFetcher(): ApiResponse<GetBroadcastVideosByIdRequest,
                    GetBroadcastVideosResponse> {
                    // get /{tenant}/broadcast-videos-by-id/{request.id}
                    return $mobileApi.getGetFetcher('{tenant}/broadcast-videos-by-id/{id}');
                },
                getBroadcastVideosByPinFetcher(): ApiResponse<GetBroadcastVideosByPinRequest,
                    GetBroadcastVideosByPinResponse> {
                    // get /{tenant}/broadcast-videos-by-pin/{request.pin}
                    return $mobileApi.getGetFetcher('{tenant}/broadcast-videos-by-pin/{pin}');
                },
            },
        },
    };
});
