import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(() => {
    const { vueApp, $settings } = useNuxtApp();
    const router = useRouter();
    const userInfo = currentUser();

    if ($settings.gtmTag) {
        vueApp.use(createGtm({
            id: $settings.gtmTag,
            vueRouter: router,
        }));
    }

    function setUserData() {
        if (userInfo.pinInfo) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer?.push({
                event: 'user_data',
                customer_id: userInfo.pinInfo.customerId,
                session_uuid: userInfo.pinInfo.sessionUuid,
                schedule_uuid: userInfo.pinInfo.schedule?.scheduleUuid,
                work_order_id: userInfo.pinInfo.workOrderId,
                work_order_pin: userInfo.pinInfo.workOrderPin,
                employee_uuid: userInfo.pinInfo.employee?.employeeUuid,
                employee_pin: userInfo.pinInfo.employee?.pin,
                fulfillment: userInfo.pinInfo.fulfillment,
                customer_key: $settings.customerKey,
                tenant: $settings.tenant,
            });
        }
    }
    watch(() => userInfo.pinInfo, setUserData);
    setUserData();
});
