import type { Coordinates } from '~/types/Location';

// Updating too often might send a number of Google Map queries, or API queries, so we're going to limit it.
const maximumUpdateMs = 5000;

export default defineNuxtPlugin((nuxt) => {
    const geolocation: {
        coords: Coordinates;
        initialized: boolean;
        denied: boolean;
    } = reactive({
        coords: {
            latitude: 0,
            longitude: 0,
            altitude: null,
            accuracy: 0,
        },
        initialized: false,
        denied: false,
    });

    let lastUpdate = 0;
    let updateTimeout: NodeJS.Timeout | undefined;

    function update(position: GeolocationPosition) {
        (Object.keys(geolocation.coords) as (keyof Coordinates)[]).forEach((k) => {
            if (geolocation.coords[k] !== position.coords[k]) {
                geolocation.coords[k] = position.coords[k]!;
            }
        });
        geolocation.initialized = true;
        geolocation.denied = false;
        lastUpdate = Date.now();
    }

    function onErr(err: GeolocationPositionError) {
        if (err.code === err.PERMISSION_DENIED) {
            geolocation.initialized = false;
            geolocation.denied = true;
        }
        else if (err.code === err.TIMEOUT) {
            console.warn('GPS Location timeout.');
        }
        else {
            console.warn(err);
        }
    }

    const w = navigator.geolocation.watchPosition((position) => {
        if (lastUpdate === 0) {
            update(position);
        }
        else {
            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(() => update(position), Math.max(0, maximumUpdateMs - (Date.now() - lastUpdate)));
        }
    }, onErr, {
        enableHighAccuracy: true,
        maximumAge: maximumUpdateMs - 50,
        timeout: 30000,
    });

    nuxt.hook('app:error', () => {
        navigator.geolocation.clearWatch(w);
        clearTimeout(updateTimeout);
    });

    return {
        provide: {
            geolocation,
        },
    };
});
