// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type { GetIncidentTemplateResponse, GetIncidentTemplateRequest } from '~/types/api/Psrm.Mobile.Api.Contract.Template';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            templateService: {
                getIncidentTemplateFetcher(): ApiResponse<GetIncidentTemplateRequest,
                    GetIncidentTemplateResponse> {
                    // get /{tenant}/Templates/Incidents/{request.clientId}
                    return $mobileApi.getGetFetcher('{tenant}/Templates/Incidents/{clientId}');
                },
            },
        },
    };
});
