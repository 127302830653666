import type { BaseService } from '@psrm/base-service';

export default defineNuxtPlugin(() => {
    const { $baseServiceBuilder, $settings } = useNuxtApp();
    const pathRequestVars = {
        tenant: $settings.tenant ?? '',
    };

    const mobileApi: BaseService & { tenantIsDefined: boolean } = {
        ...$baseServiceBuilder.build({
            apiUrl: $settings.mobileApiUrl,
            additionalHeaders: {
                'api-version': '6.0',
            },
            pathRequestVars,
        }),
        tenantIsDefined: !!$settings.tenant,
    };
    watch(() => ({ apiUrl: $settings.mobileApiUrl, tenant: $settings.tenant }), (config) => {
        pathRequestVars.tenant = config.tenant ?? '';
        mobileApi.reconfigure({
            apiUrl: config.apiUrl,
            pathRequestVars,
        });
        mobileApi.tenantIsDefined = !!config.tenant;
    });
    return {
        provide: {
            mobileApi,
        },
    };
});
