// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type { MobileSessionPunchResponse, MobileSessionPunchRequest } from '~/types/api/Psrm.Mobile.Api.Contract.GpsPunch';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            gpsPunchService: {
                ivrMobileSessionPunchPostFetcher(): ApiResponse<MobileSessionPunchRequest,
                    MobileSessionPunchResponse> {
                    // post /{tenant}/Gps/IvrMobileSessionPunch
                    return $mobileApi.getPostFetcher('{tenant}/Gps/IvrMobileSessionPunch');
                },
                mobileSessionPunchPostFetcher(): ApiResponse<MobileSessionPunchRequest,
                    unknown> {
                    // post /{tenant}/Gps/MobileSessionPunch
                    return $mobileApi.getPostFetcher('{tenant}/Gps/MobileSessionPunch');
                },
            },
        },
    };
});
