// Generated by generate-api-types.mjs
import type { ApiResponse } from '@psrm/base-service';
import type {
    GetIncidentCategoriesResponse,
    GetIncidentCategoriesRequest,
    CreateIncidentResponse,
    CreateIncidentRequest,
    IncidentPermissionsResponse,
    GetIncidentPermissionsRequest,
    CreateSupervisorVisitResponse,
    CreateSupervisorVisitRequest,
} from '~/types/api/Psrm.Mobile.Api.Contract.Incident';

export default defineNuxtPlugin(() => {
    const { $mobileApi } = useNuxtApp();

    return {
        provide: {
            incidentService: {
                createIncidentPostFetcher(): ApiResponse<CreateIncidentRequest,
                    CreateIncidentResponse> {
                    // post /{tenant}/Incident
                    return $mobileApi.getPostFetcher('{tenant}/Incident');
                },
                createSupervisorVisitPostFetcher(): ApiResponse<CreateSupervisorVisitRequest,
                    CreateSupervisorVisitResponse> {
                    // post /{tenant}/incident/supervisor-visit
                    return $mobileApi.getPostFetcher('{tenant}/incident/supervisor-visit');
                },
                getIncidentCategoriesFetcher(): ApiResponse<GetIncidentCategoriesRequest,
                    GetIncidentCategoriesResponse> {
                    // get /{tenant}/incident/categories
                    return $mobileApi.getGetFetcher('{tenant}/incident/categories');
                },
                getIncidentPermissionsFetcher(): ApiResponse<GetIncidentPermissionsRequest,
                    IncidentPermissionsResponse> {
                    // get /{tenant}/incident/permissions
                    return $mobileApi.getGetFetcher('{tenant}/incident/permissions');
                },
            },
        },
    };
});
