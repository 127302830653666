import { datadogRum } from '@datadog/browser-rum';
import { createFetch, Headers, AbortController } from 'ofetch';
import { version } from '../package.json';

export default defineNuxtPlugin(() => {
    const { $baseServiceBuilder, $settings } = useNuxtApp();
    const userInfo = currentUser();

    datadogRum.init({
        applicationId: '93e0b1ce-479a-4a9d-bbaa-cf8259caebe7',
        clientToken: 'pubcaed51092c0a19c81b5ec86576bf5867',
        site: 'datadoghq.com',
        service: 'psrm.mobile.webappv2',
        // $settings.tenant might not be known until PIN is sent... unsure how to set env after session starts.
        env: $settings.env,
        // Specify a version number to identify the deployed version of your application in Datadog
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100, // Default: 20
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
        enableExperimentalFeatures: ['feature_flags'],
        allowedTracingUrls: [
            $settings.mobileApiUrl,
        ],
    });

    datadogRum.startSessionReplayRecording();

    function setUser() {
        if (userInfo.pinInfo?.customerId) {
            const name = userInfo.pinInfo?.employee ? `${userInfo.pinInfo.employee.name} [${userInfo.pinInfo.employee.pin}]` : `W-${userInfo.pinInfo.workOrderPin}`;
            datadogRum.setUser({
                id: userInfo.pinInfo.employee?.id ? `e_${userInfo.pinInfo.employee?.id}` : `w_${userInfo.pinInfo.workOrderId}`,
                name,
                workOrderId: userInfo.pinInfo.workOrderId,
                workOrderPin: userInfo.pinInfo.workOrderPin,
                location: userInfo.pinInfo.clientLocation?.name,
                position: userInfo.pinInfo.clientPosition?.name,
                employeeId: userInfo.pinInfo.employee?.id,
                employeePin: userInfo.pinInfo.employee?.pin,
                fulfillment: userInfo.pinInfo.fulfillment,
                customerId: userInfo.pinInfo.customerId,
                scheduleId: userInfo.pinInfo.schedule?.id,
                schedule_uuid: userInfo.pinInfo.schedule?.scheduleUuid,
                // I'm 99.99999% confident these will be available if userInfo.pinInfo exists.
                tenant: $settings.tenant!,
                customerKey: $settings.customerKey!,
            });
        }
        else {
            datadogRum.clearUser();
        }
    }
    watch(() => userInfo.pinInfo, setUser);
    setUser();

    // datadog should have overwritten window.fetch
    $baseServiceBuilder.setCustomFetch(createFetch({ fetch: window.fetch, Headers, AbortController }));

    return {
        provide: {
            datadogRum,
        },
    };
});
