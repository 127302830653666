const loginRoute = '/auth';

export default defineNuxtRouteMiddleware(async (to) => {
    if (to.path.startsWith('/public')) {
        return;
    }

    const userInfo = currentUser();
    if (!userInfo.isAuthenticated) {
        // if currentUser threw an error (browser unsupported)
        return;
    }
    const isUserAuthenticated = await userInfo.isAuthenticated();
    const isLoginRoute = to.path.startsWith(loginRoute);

    // no need to authenticate again, go home
    if (isUserAuthenticated && isLoginRoute) {
        return navigateTo({ path: '/', query: { flavor: to.query.flavor, host: to.query.host } });
    }

    const isAnonRoute = to.meta.layout === 'anonymous';
    if (!isUserAuthenticated && !isLoginRoute && !isAnonRoute) {
        return navigateTo({ path: loginRoute, query: { flavor: to.query.flavor, host: to.query.host } });
    }
});
