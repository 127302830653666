<script setup lang="ts">
import type { PageMeta } from '#app';

const { $flavorManager, $settings } = useNuxtApp();
const runtimeConfig = useRuntimeConfig();
if (!$flavorManager.flavor) {
    console.error('No flavor was set.');
}
const faviconFolder = computed(() => {
    if ($settings.logo?.faviconFolder) {
        return `${runtimeConfig.app.baseURL ?? '/'}${$settings.logo.faviconFolder}`;
    }
    if (!$flavorManager.flavor) {
        return null;
    }
    return `${runtimeConfig.app.baseURL ?? '/'}flavors/${$flavorManager.flavor}/favicons`;
});
const flavorLayout = computed<PageMeta['layout']>(() => `flavor-${$flavorManager.flavor ?? 'gfx'}`);

useHead({
    titleTemplate: (t) => {
        const defaultTitle = 'IVR';
        let subtitle = '';
        if (t && t !== defaultTitle) {
            subtitle = t;
        }
        if (subtitle) {
            return `${subtitle} - ${defaultTitle}`;
        }
        return defaultTitle;
    },
});
</script>

<template>
    <div>
        <Head>
            <Meta name="viewport" content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=2, interactive-widget=resizes-content" />
            <Meta
                v-if="$flavorManager.flavor"
                name="theme-color"
                :content="$flavorManager.themeColor()"
            />
            <template v-if="$flavorManager.flavor">
                <template v-if="faviconFolder !== null">
                    <Link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        :href="`${faviconFolder}/apple-touch-icon.png`"
                    />
                    <Link
                        rel="shortcut icon"
                        type="image/png"
                        sizes="32x32"
                        :href="`${faviconFolder}/favicon_32.png`"
                    />
                    <Link
                        rel="shortcut icon"
                        type="image/png"
                        sizes="16x16"
                        :href="`${faviconFolder}/favicon_16.png`"
                    />
                    <Link
                        rel="shortcut icon"
                        type="image/x-icon"
                        :href="`${faviconFolder}/favicon.ico`"
                    />
                </template>
            </template>
        </Head>
        <NuxtLayout :name="flavorLayout">
            <NuxtLayout name="container">
                <NuxtPage />
            </NuxtLayout>
        </NuxtLayout>
        <VersionNotifier />
    </div>
</template>
