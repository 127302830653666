/**
 * Avoid race conditions by waiting until `resolver` is true
 *
 * @param resolver callback function should return true if all needed dependencies are resolved.
 * @param retryMs how often should we check `resolver` (Default 300)
 * @param maxWaitMs time in ms to wait until we return even if not resolved. (Default 10_000)
 * @returns true if resolved, false if timeout reached.
 */
export async function waitUntilResolved(resolver: () => boolean, retryMs: number = 300, maxWaitMs: number = 10000) {
    const start = Date.now();
    while (!resolver()) {
        if (Date.now() - start > maxWaitMs) {
            return false;
        }
        await new Promise(resolve => setTimeout(resolve, retryMs));
    }
    return true;
}
