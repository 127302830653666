// https://dev.to/ceceliacreates/using-fontawesome-icons-with-nuxt-3-50cd
// TODO extract this to Psrm.WebApp.CoreV2 with configurable icons (@nuxtjs/fontawesome has similar functionality but is outdated)

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { library, config } from '@fortawesome/fontawesome-svg-core';

// There's an error in eslint that causes an issue with this alias.

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import 'font-awesome-animation/css/font-awesome-animation.min.css';

import {
    faAnglesRight,
    faArrowUpFromBracket,
    faCheck,
    faChevronRight,
    faCircleCheck,
    faCircleInfo,
    faCircleNotch,
    faCircleXmark,
    faEllipsisVertical,
    faEnvelope,
    faGear,
    faLocationDot,
    faPhone,
    faPlay,
    faRightFromBracket,
    faTimes,
    faTrash,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

const fontAwesomeIcons: IconDefinition[] = [
    faAnglesRight,
    faArrowUpFromBracket,
    faCheck,
    faChevronRight,
    faCircleCheck,
    faCircleInfo,
    faCircleNotch,
    faCircleXmark,
    faEllipsisVertical,
    faEnvelope,
    faGear,
    faLocationDot,
    faPhone,
    faPlay,
    faRightFromBracket,
    faTimes,
    faTrash,
    faTriangleExclamation,
];

library.add(...fontAwesomeIcons);

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
    nuxtApp.vueApp.component('FontAwesomeLayers', FontAwesomeLayers);
});
