export type Keyless<T> = T extends { key: unknown } ? never : T;

export function addKey<T>(value: Keyless<T>): T & { key: number } {
    return {
        ...value,
        key: createKey(),
    };
}

export function createKey(): number {
    return Math.random();
}
